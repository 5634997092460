var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',{staticClass:"selection-header",staticStyle:{"font-size":"13px","font-family":"Work Sans"}},[_vm._v("Indicators Selection")]),_c('Card',{staticClass:"scroll"},[(_vm.loading == true)?_c('TheLoader',{staticStyle:{"margin":"60px 0px 0px 119px"}}):_vm._e(),_vm._l((_vm.heading),function(items,idx){return _c('div',{key:idx,staticStyle:{"margin-top":"-8px"}},[_c('div',{staticClass:"program-areas my-2",staticStyle:{"background":"#f3f3f3","font-size":"13px"}},[_c('input',{staticClass:"checkbox no-pointer-events",attrs:{"type":"checkbox","id":items.parent.value},domProps:{"checked":_vm.isAllSelected(items.parent)},on:{"click":function($event){return _vm.toggleAll(
              $event,
              items.children,
              items.parent.value,
              items.parent.selected
            )}}}),_c('label',{staticStyle:{"cursor":"pointer","font-weight":"normal","font-size":"13px","font-family":"Work Sans","color":"#202020","margin-left":"-4px"},attrs:{"for":items.parent.value}},[_vm._v(" "+_vm._s(items.parent.value)+" ")]),_c('span',{staticStyle:{"float":"right"}},[_vm._v("▼")])]),_vm._l((items.children),function(item,index){return _c('div',{key:index,staticClass:"indicators",staticStyle:{"margin-bottom":"3px","font-size":"13px"}},[_c('input',{staticClass:"checkbox no-pointer-events",attrs:{"type":"checkbox","name":"child","id":item.short_name},domProps:{"value":item.short_name,"checked":_vm.isSelected(item)},on:{"click":function($event){return _vm.selectIndicator(
              $event,
              items.parent.value,
              item.id,
              item.short_name,
              item.selected
            )}}}),_c('label',{staticStyle:{"cursor":"pointer","padding-left":"5px","font-size":"12px","margin-left":"-4px","font-family":"Work Sans"},attrs:{"for":item.short_name}},[_vm._v(" "+_vm._s(item.short_name)+" ")])])})],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }