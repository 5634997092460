var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',{staticClass:"selection-header",staticStyle:{"font-size":"13px","font-family":"Work Sans"}},[_vm._v("Data Source Selection")]),_c('br'),_c('Card',{staticClass:"scroll"},[(_vm.loading == true)?_c('TheLoader',{staticStyle:{"margin":"60px 0px 0px 119px"}}):_vm._e(),_vm._l((_vm.sources),function(items,idx){return _c('div',{key:idx},[_c('div',{staticClass:"program-areas",staticStyle:{"background":"#f3f3f3","font-size":"13px"}},[_c('span',{staticStyle:{"font-weight":"normal","font-family":"Work Sans","letter-spacing":"0px","color":"#202020","padding-left":"13px","font-size":"13px"}},[_vm._v(" "+_vm._s(items.parent)+" ")]),_c('span',{staticStyle:{"float":"right"}},[_vm._v("▼")])]),_vm._l((items.children),function(item){return _c('div',{key:item.id,staticClass:"indicators col-4",staticStyle:{"display":"inline-block","justify-content":"space-around","font-size":"13px","margin":"0px"}},[_c('input',{staticClass:"checkbox no-pointer-events",attrs:{"type":"checkbox","name":"","id":item.datasource},domProps:{"value":item.datasource,"checked":_vm.isSelected(item)},on:{"click":function($event){return _vm.selectSource(
              $event,
              items.parent.value,
              item.id,
              item.datasource,
              item.selected
            )}}}),_c('label',{staticStyle:{"cursor":"pointer","font-size":"10px","padding-left":"5px","font-family":"Work Sans","margin-left":"-8px"},attrs:{"for":item.datasource}},[_vm._v(" "+_vm._s(item.datasource)+" ")])])})],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }